import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import './tier-list.scss';
import { EversoulCharacter } from '../../modules/es/common/components/eversoul-character';
import { Accordion, Button } from 'react-bootstrap';
import lodash from 'lodash';
import {
  faAsterisk,
  faShieldHalved,
  faFlask,
  faHandFist,
  faVirus,
  faAnglesUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { ESCharacterRatings } from '../../modules/es/ratings/es-ratings';

const EsTierPage: React.FC = () => {
  const [visibleCharacters, setVisibleCharacters] =
    useState(ESCharacterRatings);

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Human',
            tooltip: 'Human',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_human.png"
                width={24}
                alt="Human"
              />
            )
          },
          {
            value: 'Beast',
            tooltip: 'Beast',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_beast.png"
                width={24}
                alt="Beast"
              />
            )
          },
          {
            value: 'Fairy',
            tooltip: 'Fairy',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_fairy.png"
                width={24}
                alt="Fairy"
              />
            )
          },
          {
            value: 'Undead',
            tooltip: 'Undead',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_undead.png"
                width={24}
                alt="Undead"
              />
            )
          },
          {
            value: 'Angel',
            tooltip: 'Angel',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_angel.png"
                width={24}
                alt="Angel"
              />
            )
          },
          {
            value: 'Demon',
            tooltip: 'Demon',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_demon.png"
                width={24}
                alt="Demon"
              />
            )
          },
          {
            value: 'Chaotic',
            tooltip: 'Chaotic',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_chaotic.png"
                width={24}
                alt="Chaotic"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'caster',
            tooltip: 'Caster',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_caster.png"
                width={24}
                alt="Caster"
              />
            )
          },
          {
            value: 'defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_defender.png"
                width={24}
                alt="Defender"
              />
            )
          },
          {
            value: 'ranger',
            tooltip: 'Ranger',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_ranger.png"
                width={24}
                alt="Ranger"
              />
            )
          },
          {
            value: 'striker',
            tooltip: 'Striker',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_striker.png"
                width={24}
                alt="Striker"
              />
            )
          },
          {
            value: 'supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_supporter.png"
                width={24}
                alt="Supporter"
              />
            )
          },
          {
            value: 'warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = ESCharacterRatings;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.Character.toLowerCase().indexOf(
              activeFilters.searchText.toLowerCase()
            ) > -1
        );
        filtered = lodash.uniqBy(filtered, 'ID');
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.Class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.Race.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, visibleCharacters, sortOption]);

  const sortingOptions = [
    { value: 'PVE', label: 'PVE (Endgame)' },
    { value: 'Boss_Pluto', label: 'Boss (Overall)' },
    { value: 'Boss_Charite', label: 'Boss (Charite)' },
    { value: 'Boss_Gaia', label: 'Boss (Gaia)' },
    { value: 'Boss_Rudra', label: 'Boss (Rudra)' },
    { value: 'Boss_Knight', label: 'Boss (Knight)' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page es-tier'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/eversoul/categories/category_tierlist.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Eversoul Tier list</h1>
          <h2>Tier list for Eversoul gacha game.</h2>
          <p>
            Last updated: <strong>08/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <SectionHeader title="Eversoul Tier List" />
      <Accordion className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>About the Tier List</Accordion.Header>
          <Accordion.Body>
            <p>
              For better and cleaner view we have opted to split Eversoul Tier
              list into <strong>4 Role Based Categories</strong> (DPS, Tanks,
              Buffer & Sustain, Debuffers) to properly present information in
              current stages of meta and diverse content. Characters can also
              appear in multiple roles - this happens when they have versatile
              kits that allow them to fulfill multiple roles.
            </p>
            <p>
              Furthermore to facilitate easier teambuilding, the tier list now
              features <strong>Tags</strong> under the char portrait - which is
              used to display Soul's Secondary Role if any marked in [] (along
              with some important/major ones having received a rating on the
              same). Besides that, they feature Team Wide/Multiple Allies Buff |
              Enemy Debuffs in that format. eg - Talia's Atk / Def / Mana ▲ |
              Acc ▼ mean she increases allies Attack, Defence and Mana while
              decreasing enemy Accuracy.
            </p>
            <h6>Artifact Investment</h6>
            <p>
              Artifacts are core part of character strength and viability in
              Eversoul, as such the basic assumption of tier list is minimum
              Legendary+ Artifact Investment with the following exceptions:
            </p>
            <ul>
              <li>
                Characters marked with either of OG/ET+/ET in top left AND $
                sign in top right are characters that REQUIRE the corresponding
                Artifact Investment to justify their placement in tier list.
              </li>
              <li>
                Characters marked with either of OG/ET+/ET in top left alone are
                rated with those Artifact ascensions in mind but are
                RECOMMENDATORY in nature and will not significantly affect their
                placements if using lower ascension.
              </li>
              <li>
                Characters marked with $ sign in top right alone are characters
                that are worthy of Origin Artifact Investment for raids
                min-maxing.
              </li>
            </ul>
            <p>
              All characters are assumed to be at least Origin Ascension in all
              tier lists and are sorted alphabetically within tiers.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Changelog</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>08/10/2024</strong>
            </p>
            <ul>
              <li>
                Updated Linzy Thanatos ratings post the 26/09/2024 balance
                patch.
              </li>
              <li>Added Bossing (Pluto) tier list.</li>
            </ul>
            <p>
              <strong>22/09/2024</strong>
            </p>
            <ul>
              <li>Added Linzy Thanatos to the tier list.</li>
              <li>Gaia changes:</li>
              <ul>
                <li>
                  Sigrid A &gt; SSS, Surprising everyone, Sigrid out of nowhere
                  claimed the top spot in Guild Raid Gaia as DPS. Her self-buffs
                  ramp her damage to insane levels.
                </li>
                <li>
                  Lizelotte SSS &gt; SS, with Sigrid taking the helm, Lizelotte
                  is failing in use in top rankers team, but still holds value
                  for the average player.
                </li>
                <li>
                  Naomi S &gt; SS, Naomi making return to Gaia Meta in style.
                </li>
                <li>
                  Violette SS &gt; S, Lack of use in ranker teams and recent
                  souls overpowering her damage.
                </li>
                <li>
                  Erusha C &gt; S, We are surprised just as anyone else, but
                  Onyx enabled a never seen before DPS Erusha comp, where she
                  does surprisingly well earning her a place among situational
                  DPS!
                </li>
              </ul>
              <li>Dark Knight changes:</li>
              <ul>
                <li>
                  Hazel S &gt; SS, Hazel continues showing a strong trend with
                  her damage at Origin Artifact, setting herself free from
                  Claudia team as sub DPS and instead working as Main DPS in her
                  standalone team, with enough investment she can reach SSS tier
                  even!
                </li>
                <li>
                  Ayame & Velanna SS &gt; S, Introduction of Sigrid cut down
                  their usage even further and Linzy (Thanatos) is expected to
                  make it worse.
                </li>
                <li>
                  Dominique A &gt; S, The Queen of Troyca continues to do well
                  with her DPS prompting this increase.
                </li>
              </ul>
              <li>Guild Raid Overall changes:</li>
              <ul>
                <li>
                  Hazel S &gt; SS, Her continued dominance in various Guild
                  Raids at the top level, earns her a spot in Meta unit, however
                  do note the Origin Artifact Investment as pre-cursor.
                </li>
                <li>
                  Sigrid, A &gt; S, Her sheer dominance in Gaia and Dark Knight
                  is enough for this bump, We are keeping an eye out for her
                  performance in Charite/Rudra during their rerun!
                </li>
              </ul>
            </ul>
            <p>
              <strong>01/09/2024</strong>
            </p>
            <ul>
              <li>Added Onyx to the tier list.</li>
            </ul>
            <p>
              <strong>11/08/2024</strong>
            </p>
            <ul>
              <li>Added Sigrid to the tier list.</li>
            </ul>
            <p>
              <strong>01/08/2024</strong>
            </p>
            <ul>
              <li>
                Tier list has been fully reworked. Check our blog for the
                details.
              </li>
            </ul>
            <p>
              <strong>20/07/2024</strong>
            </p>
            <ul>
              <li>Added recently released characters to the tier list.</li>
            </ul>
            <p>
              <strong>09/06/2024</strong>
            </p>
            <ul>
              <li>Added Hazel to the tier list.</li>
            </ul>
            <p>
              <strong>17/05/2024</strong>
            </p>
            <ul>
              <li>Added Catherine (Radience) to the tier list.</li>
            </ul>
            <p>
              <strong>02/05/2024</strong>
            </p>
            <ul>
              <li>Added Sakuyo to the tier list.</li>
            </ul>
            <p>
              <strong>08/04/2024</strong>
            </p>
            <ul>
              <li>Added Joanne to the tier list.</li>
            </ul>
            <p>
              <strong>29/03/2024</strong>
            </p>
            <ul>
              <li>Added Kanna to the tier list.</li>
            </ul>
            <p>
              <strong>26/02/2024</strong>
            </p>
            <ul>
              <li>Added Beleth to the tier list.</li>
            </ul>
            <p>
              <strong>13/02/2024</strong>
            </p>
            <ul>
              <li>Charite tier list has been updated:</li>
              <ul>
                <li>Clara A &gt; SS</li>
                <li>Eileen SSS &gt; SS</li>
                <li>Erika S &gt; A</li>
                <li>Eve S &gt; SS</li>
                <li>Jade A &gt; SS</li>
                <li>Linzy SS &gt; S</li>
                <li>Naomi SS &gt; SSS</li>
                <li>Prim SS &gt; S</li>
                <li>Talia SS &gt; S</li>
                <li>Tasha A &gt; SS</li>
                <li>Violette SSS &gt; S</li>
              </ul>
            </ul>
            <p>
              <strong>03/02/2024</strong>
            </p>
            <ul>
              <li>Added Tasha to the tier list.</li>
            </ul>
            <p>
              <strong>18/01/2024</strong>
            </p>
            <ul>
              <li>
                <strong>PVE</strong>
              </li>
              <ul>
                <li>Eve - SS (New)</li>
              </ul>
              <li>
                <strong>Charite</strong>
              </li>
              <ul>
                <li>Eve - S (New)</li>
                <li>Vivienne - S --&gt; A</li>
              </ul>
              <li>
                <strong>Gaia</strong>
              </li>
              <ul>
                <li>Eve - SSS (New)</li>
                <li>Rebecca - SSS --&gt; SS</li>
                <li>Ayame - SSS --&gt; SS</li>
                <li>Edith - SS --&gt; S </li>
                <li>Xiaolian - C --&gt; S</li>
              </ul>
              <li>
                <strong>Ruthra</strong>
              </li>
              <ul>
                <li>Eve - SS (New)</li>
                <li>Haru - SS --&gt; S</li>
                <li>Xiaolian - C --&gt; A</li>
                <li>Aki - A --&gt; C</li>
              </ul>
              <li>
                <strong>Dark Knight</strong>
              </li>
              <ul>
                <li>Eve - SSS (New)</li>
                <li>Petra - S --&gt; SS</li>
                <li>Linzy - A --&gt; SS</li>
                <li>Soonie - B --&gt; S</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="banner eversoul-promo">
        <h4>Play Eversoul on PC with LDPlayer</h4>
        <OutboundLink
          href="https://en.ldplayer.net/games/eversoul-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
          target="_blank"
        >
          <Button variant="primary">Play now for free</Button>
        </OutboundLink>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-header">
        <p className="title">
          Tier List{' '}
          <span>
            ({sortOption === 'PVE' && 'PVE - endgame'}
            {sortOption === 'Boss_Pluto' && 'Boss - Pluto'}
            {sortOption === 'Boss_Charite' && 'Boss - Charite'}
            {sortOption === 'Boss_Gaia' && 'Boss - Gaia'}
            {sortOption === 'Boss_Rudra' && 'Boss - Rudra'}
            {sortOption === 'Boss_Knight' && 'Boss - Knight'})
          </span>
        </p>
        <p className="sub-title">
          You're currently viewing the{' '}
          <strong>
            {sortOption === 'PVE' && 'PVE - endgame'}
            {sortOption === 'Boss_Pluto' && 'Boss - Pluto'}
            {sortOption === 'Boss_Charite' && 'Boss - Charite'}
            {sortOption === 'Boss_Gaia' && 'Boss - Gaia'}
            {sortOption === 'Boss_Rudra' && 'Boss - Rudra'}
            {sortOption === 'Boss_Knight' && 'Boss - Knight'}
          </strong>{' '}
          tier list. It shows{' '}
          {sortOption === 'PVE' &&
            'how a character performs in endgame PVE Content - Origin ascension and Leg+ Artifact.'}
          {sortOption === 'Boss_Pluto' &&
            'how a character performs against Pluto.'}
          {sortOption === 'Boss_Charite' &&
            'how a character performs against Charite.'}
          {sortOption === 'Boss_Gaia' &&
            'how a character performs against Gaia.'}
          {sortOption === 'Boss_Rudra' &&
            'how a character performs against Rudra.'}
          {sortOption === 'Boss_Knight' &&
            'how a character performs against Knight.'}
        </p>
      </div>
      <div className="tier-list-switcher">
        <div
          className={`option pve_general ${sortOption === 'PVE' && 'selected'}`}
          onClick={() => setSortOption('PVE')}
        >
          <p>PVE - Endgame</p>
        </div>
        <div
          className={`option boss_pluto ${
            sortOption === 'Boss_Pluto' && 'selected'
          }`}
          onClick={() => setSortOption('Boss_Pluto')}
        >
          <p>Boss - Pluto</p>
        </div>
        <div
          className={`option boss_charite ${
            sortOption === 'Boss_Charite' && 'selected'
          }`}
          onClick={() => setSortOption('Boss_Charite')}
        >
          <p>Boss - Charite</p>
        </div>
        <div
          className={`option boss_gaia ${
            sortOption === 'Boss_Gaia' && 'selected'
          }`}
          onClick={() => setSortOption('Boss_Gaia')}
        >
          <p>Boss - Gaia</p>
        </div>
        <div
          className={`option boss_rudra ${
            sortOption === 'Boss_Rudra' && 'selected'
          }`}
          onClick={() => setSortOption('Boss_Rudra')}
        >
          <p>Boss - Ruthra</p>
        </div>
        <div
          className={`option boss_knight ${
            sortOption === 'Boss_Knight' && 'selected'
          }`}
          onClick={() => setSortOption('Boss_Knight')}
        >
          <p>Boss - Dark Knight</p>
        </div>
      </div>
      <div className="employees-filter-bar eversoul">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="custom-tier-list-es">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
            </div>
            <div className="custom-header debuffer">
              <FontAwesomeIcon icon={faVirus} width="18" /> Debuffer
            </div>
            <div className="custom-header support">
              <FontAwesomeIcon icon={faFlask} width="18" /> Buffer / Sustain
            </div>
            <div className="custom-header sustain">
              <FontAwesomeIcon icon={faShieldHalved} width="18" /> Tank
            </div>
          </div>
        </div>
        <div className="custom-tier tier-sss first">
          <div className="tier-rating sss">
            <span>SSS</span>
          </div>
          <div className="custom-tier-container">
            <div className="burst-type-mobile dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
            </div>
            <div className="custom-tier-burst dps">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'SSS')
                .filter((emp) => emp.Role === 'DPS')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile debuffer">
              <FontAwesomeIcon icon={faVirus} width="18" /> Debuffer
            </div>
            <div className="custom-tier-burst debuffer">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'SSS')
                .filter((emp) => emp.Role === 'Debuffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile support">
              <FontAwesomeIcon icon={faFlask} width="18" /> Buffer / Sustain
            </div>
            <div className="custom-tier-burst support">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'SSS')
                .filter((emp) => emp.Role === 'Buffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile sustain">
              <FontAwesomeIcon icon={faShieldHalved} width="18" /> Tank
            </div>
            <div className="custom-tier-burst sustain">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'SSS')
                .filter((emp) => emp.Role === 'Tank')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header bis">
            <FontAwesomeIcon icon={faAnglesUp} width="18" /> Best in Slot{' '}
            <FontAwesomeIcon icon={faAnglesUp} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-ss">
          <div className="tier-rating ss">
            <span>SS</span>
          </div>
          <div className="custom-tier-container">
            <div className="burst-type-mobile dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
            </div>
            <div className="custom-tier-burst dps">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'SS')
                .filter((emp) => emp.Role === 'DPS')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile debuffer">
              <FontAwesomeIcon icon={faVirus} width="18" /> Debuffer
            </div>
            <div className="custom-tier-burst debuffer">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'SS')
                .filter((emp) => emp.Role === 'Debuffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile support">
              <FontAwesomeIcon icon={faFlask} width="18" /> Buffer / Sustain
            </div>
            <div className="custom-tier-burst support">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'SS')
                .filter((emp) => emp.Role === 'Buffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile sustain">
              <FontAwesomeIcon icon={faShieldHalved} width="18" /> Tank
            </div>
            <div className="custom-tier-burst sustain">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'SS')
                .filter((emp) => emp.Role === 'Tank')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line ${sortOption != 'PVE' && 'visible'}`}>
          <h5 className="meta-header meta">
            <FontAwesomeIcon icon={faAnglesUp} width="18" /> Meta{' '}
            <FontAwesomeIcon icon={faAnglesUp} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s">
          <div className="tier-rating s">
            <span>S</span>
          </div>
          <div className="custom-tier-container">
            <div className="burst-type-mobile dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
            </div>
            <div className="custom-tier-burst dps">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'S')
                .filter((emp) => emp.Role === 'DPS')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile debuffer">
              <FontAwesomeIcon icon={faVirus} width="18" /> Debuffer
            </div>
            <div className="custom-tier-burst debuffer">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'S')
                .filter((emp) => emp.Role === 'Debuffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile support">
              <FontAwesomeIcon icon={faFlask} width="18" /> Buffer / Sustain
            </div>
            <div className="custom-tier-burst support">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'S')
                .filter((emp) => emp.Role === 'Buffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile sustain">
              <FontAwesomeIcon icon={faShieldHalved} width="18" /> Tank
            </div>
            <div className="custom-tier-burst sustain">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'S')
                .filter((emp) => emp.Role === 'Tank')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line ${sortOption === 'PVE' && 'visible'}`}>
          <h5 className="meta-header meta">
            <FontAwesomeIcon icon={faAnglesUp} width="18" /> Meta{' '}
            <FontAwesomeIcon icon={faAnglesUp} width="18" />
          </h5>
        </div>
        <div className={`meta-line ${sortOption != 'PVE' && 'visible'}`}>
          <h5 className="meta-header alt">
            <FontAwesomeIcon icon={faAnglesUp} width="18" /> Best replacement /
            situational <FontAwesomeIcon icon={faAnglesUp} width="18" />
          </h5>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
        <div className="custom-tier tier-a">
          <div className="tier-rating a">
            <span>A</span>
          </div>
          <div className="custom-tier-container">
            <div className="burst-type-mobile dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
            </div>
            <div className="custom-tier-burst dps">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'A')
                .filter((emp) => emp.Role === 'DPS')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile debuffer">
              <FontAwesomeIcon icon={faVirus} width="18" /> Debuffer
            </div>
            <div className="custom-tier-burst debuffer">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'A')
                .filter((emp) => emp.Role === 'Debuffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile support">
              <FontAwesomeIcon icon={faFlask} width="18" /> Buffer / Sustain
            </div>
            <div className="custom-tier-burst support">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'A')
                .filter((emp) => emp.Role === 'Buffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile sustain">
              <FontAwesomeIcon icon={faShieldHalved} width="18" /> Tank
            </div>
            <div className="custom-tier-burst sustain">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'A')
                .filter((emp) => emp.Role === 'Tank')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line ${sortOption === 'PVE' && 'visible'}`}>
          <h5 className="meta-header alt">
            <FontAwesomeIcon icon={faAnglesUp} width="18" /> Best replacement /
            situational <FontAwesomeIcon icon={faAnglesUp} width="18" />
          </h5>
        </div>
        <div className={`meta-line ${sortOption != 'PVE' && 'visible'}`}>
          <h5 className="meta-header niche">
            <FontAwesomeIcon icon={faAnglesUp} width="18" /> Niche Picks{' '}
            <FontAwesomeIcon icon={faAnglesUp} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-b">
          <div className="tier-rating b">
            <span>B</span>
          </div>
          <div className="custom-tier-container">
            <div className="burst-type-mobile dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
            </div>
            <div className="custom-tier-burst dps">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'B')
                .filter((emp) => emp.Role === 'DPS')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile debuffer">
              <FontAwesomeIcon icon={faVirus} width="18" /> Debuffer
            </div>
            <div className="custom-tier-burst debuffer">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'B')
                .filter((emp) => emp.Role === 'Debuffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile support">
              <FontAwesomeIcon icon={faFlask} width="18" /> Buffer / Sustain
            </div>
            <div className="custom-tier-burst support">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'B')
                .filter((emp) => emp.Role === 'Buffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile sustain">
              <FontAwesomeIcon icon={faShieldHalved} width="18" /> Tank
            </div>
            <div className="custom-tier-burst sustain">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'B')
                .filter((emp) => emp.Role === 'Tank')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line ${sortOption === 'PVE' && 'visible'}`}>
          <h5 className="meta-header niche">
            <FontAwesomeIcon icon={faAnglesUp} width="18" /> Niche Picks{' '}
            <FontAwesomeIcon icon={faAnglesUp} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-c">
          <div className="tier-rating c">
            <span>C</span>
          </div>
          <div className="custom-tier-container">
            <div className="burst-type-mobile dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
            </div>
            <div className="custom-tier-burst dps">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'C')
                .filter((emp) => emp.Role === 'DPS')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile debuffer">
              <FontAwesomeIcon icon={faVirus} width="18" /> Debuffer
            </div>
            <div className="custom-tier-burst debuffer">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'C')
                .filter((emp) => emp.Role === 'Debuffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile support">
              <FontAwesomeIcon icon={faFlask} width="18" /> Buffer / Sustain
            </div>
            <div className="custom-tier-burst support">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'C')
                .filter((emp) => emp.Role === 'Buffer')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="burst-type-mobile sustain">
              <FontAwesomeIcon icon={faShieldHalved} width="18" /> Tank
            </div>
            <div className="custom-tier-burst sustain">
              {visibleCharacters
                .filter((emp) => emp[sortOption] === 'C')
                .filter((emp) => emp.Role === 'Tank')
                .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index} className="char-card">
                      <EversoulCharacter
                        slug={emp.Slug}
                        mode="icon"
                        enablePopover
                        showTierTags
                        passTags={emp.Tags}
                        artifact={emp.Artifact}
                        investment={emp.Investment}
                      />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
    </DashboardLayout>
  );
};

export default EsTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Eversoul | Prydwen Institute"
    description="Tier list for Eversoul gacha game."
    game="eversoul"
  />
);
